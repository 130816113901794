import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosLite, CallAxios } from "../../Helpers";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import DataGrid from "../../Components/DataGrid";
import { ExclaimationCircle } from "../../Components/ActionBtns";
import { createGlobalStyle } from "styled-components";
import { ActionItem, ActionsBox, ContentBox, ContentSubHeader, HeaderRadio, SubHeadTitle, SubHeaderTitleBox, SubHeaderWrapper } from "../../Helpers/Global/StyledComponents";
import { HiUserGroup } from "react-icons/hi";
import { config } from "../../Helpers/Config";

const columns = [
  { key: 'supportChatId', name: 'Ticket Number', sortable: true, searchable: true, disableSearch: false },
  { key: 'ticketName', name: 'Ticket Name', searchable: true, disableSearch: false },
  { key: 'clientName', name: 'Client Name', searchable: true, sortable: true, disableSearch: false },
  { key: 'supportUserName', name: 'Support User', searchable: true, sortable: true, disableSearch: false },
  { key: 'companyName', name: 'Company Name', searchable: true, sortable: true, disableSearch: false },
  { key: 'status', name: 'Status', searchable: true, sortable: true, disableSearch: false },
  { key: 'actions', name: 'Actions' },
];


function TicketsList() {
  const [SupportTicketList, setSupportTicketList] = useState([]);
  const [FilterSupportTicketList, setFilterSupportTicketList] = useState([]);
  const [ShowChatDetailModal, setShowChatDetailModal] = useState(false);
  const [SupportChatId, setSupportChatId] = useState(null);
  const [checkRadio, setCheckRadio] = useState("open");

  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    var response = await AxiosLite("api/AdminPanel/SupportChat/GetSupportTicketsList|get")
    setSupportTicketList(response.data)
    setFilterSupportTicketList(response.data.filter(x => x.status === true))

  }
  useEffect(() => {
    if (SupportTicketList.length > 0) {
      if (checkRadio === "open") {
        setFilterSupportTicketList(SupportTicketList.filter(x => x.status === true))
      } else if (checkRadio === "archived") {
        setFilterSupportTicketList(SupportTicketList.filter(x => x.status === false))
      } else {
        setFilterSupportTicketList(SupportTicketList)
      }
    } else {
      setFilterSupportTicketList([])
    }
  }, [checkRadio]);
  return (
    <>
      <ContentSubHeader>
        <SubHeaderWrapper>
          <SubHeaderTitleBox>
            <HiUserGroup color="rgb(1, 219, 169)" size={22} />
            <SubHeadTitle>Support Tickets</SubHeadTitle>
          </SubHeaderTitleBox>
          <ActionsBox>
            <ActionItem>
              All: <HeaderRadio onChange={() => setCheckRadio('all')} data-tooltip-id="my-tooltip" data-tooltip-content="All Tickets" name='check' type='radio' checked={checkRadio === "all" ? true : false} />
            </ActionItem>
            <ActionItem>
              Open: <HeaderRadio onChange={() => setCheckRadio('open')} data-tooltip-id="my-tooltip" data-tooltip-content="Open Tickets" name='check' type='radio' checked={checkRadio === "open" ? true : false} />
            </ActionItem>
            <ActionItem>
              Archived: <HeaderRadio onChange={() => setCheckRadio('archived')} data-tooltip-id="my-tooltip" data-tooltip-content="Archived Tickets" name='check' type='radio' checked={checkRadio === "archived" ? true : false} />
            </ActionItem>
          </ActionsBox>
        </SubHeaderWrapper>
      </ContentSubHeader>
      <ContentBox>
        <Container fluid>
          {
            ShowChatDetailModal &&
            <ChatDetailModal show={ShowChatDetailModal} onHide={setShowChatDetailModal} setSupportChatId={setSupportChatId} SupportChatId={SupportChatId} />
          }
          <Col md={12} className="p-4">
            <Row>
              <Col md={12} className="mb-3 ">
                <Row>
                  <DataGrid
                    columns={columns}
                    rows={FilterSupportTicketList}

                    customBody={(rows) =>
                      rows?.map((value, i) =>
                        <tr>
                          <td>
                            <div className="dataTextCell">
                              {value.supportChatId}
                            </div>
                          </td>
                          <td>
                            <div className="dataTextCell">
                              {value.ticketName}
                            </div>
                          </td>
                          <td>
                            <div className="dataTextCell">
                              {value.clientName}
                            </div>
                          </td>
                          <td>
                            <div className="dataTextCell">
                              {!!value.supportUserName ? value.supportUserName : "Waiting..."}
                            </div>
                          </td>
                          <td>
                            <div className="dataTextCell">
                              {value.companyName}
                            </div>
                          </td>
                          <td>
                            <div className="dataTextCell">
                              {value.status ? "OPEN" : 'Archived'}
                            </div>
                          </td>
                          <td>
                            <div className="dataTextCell">
                              <div className="d-flex" data-tooltip-id="my-tooltip" data-tooltip-content='Chat Detail' onClick={() => { setSupportChatId(value.supportChatId); setShowChatDetailModal(true) }}>
                                <ExclaimationCircle color='#a4a4a4' />
                              </div>
                            </div>
                          </td>
                        </tr>
                      )}
                  />

                </Row>
              </Col>
            </Row>
          </Col>
        </Container>
      </ContentBox>

    </>
  )
}
export default TicketsList;

function ChatDetailModal(props) {
  const [ActiveChatMessagesList, setActiveChatMessagesList] = useState([]);
  const [SupportUsrID, setSupportUsrID] = useState(null);
  const [comment, setComment] = useState("");

  useEffect(() => {
    GteDtata()
  }, []);
  const GteDtata = async () => {
    var res = await CallAxios("api/AdminPanel/SupportChat/GetSupportChatsDetail|post", { id: props.SupportChatId.toString() })
    setComment(res.data.summary)
    setActiveChatMessagesList(res.data.messages)
    setSupportUsrID(res.data.supportChatUserId)
  }
  return (
    <>
      <Modal show={props.show} className="largeModalDialog" onHide={() => { props.setSupportChatId(null); props.onHide(false) }}>
        <Modal.Header closeButton className="position-relative absoluteModalClose">
          <Modal.Title>Chat Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Globalstyle />
          <Form>
            <Row>
              <Col md={12}>
                <div className="chat-panel flex-grow-1">
                  <div className='chatBoxHeight msg-box'>
                    {
                      !!ActiveChatMessagesList && ActiveChatMessagesList.length > 0 &&
                      ActiveChatMessagesList.map((val, key) => {
                        return <>
                          {
                            val.senderId === SupportUsrID ?
                              <div className="row no-gutters">
                                <div className="col-md-12 d-flex align-item-center justify-content-end">
                                  {
                                    val.isAttachment ?
                                      <div className="chat-bubble chat-bubble--right">
                                        <a target='_blank' href={config.S3BucketURL + val.message}>
                                          {
                                            val.attachmentType === "image/png" || val.attachmentType === "image/jpeg" ?
                                              <img width={150} height={150} src={config.S3BucketURL + val.message} />
                                              :
                                              val.attachmentType === "application/pdf" ?
                                                <img width={150} height={100} src='/pdf-icon.png' />
                                                :
                                                val.attachmentType === "application/vnd.ms-excel" || val.attachmentType === "text/csv" || val.attachmentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ?
                                                  <img width={150} height={100} src='/excellogo.png' />
                                                  : <img width={150} height={100} src='/msdoc.png' />
                                          }
                                        </a>
                                      </div>
                                      :
                                      <div className="chat-bubble chat-bubble--right">
                                        {val.message}
                                      </div>
                                  }
                                </div>
                              </div>
                              :
                              <div className="row no-gutters">
                                <div className="col-md-12">
                                  {
                                    val.isAttachment ?
                                      <div className="chat-bubble chat-bubble--left">
                                        <a target='_blank' href={config.S3BucketURL + val.message}>
                                          {
                                            val.attachmentType === "image/png" || val.attachmentType === "image/jpeg" ?
                                              <img width={150} height={150} src={config.S3BucketURL + val.message} />
                                              :
                                              val.attachmentType === "application/pdf" ?
                                                <img width={150} height={100} src='/pdf-icon.png' />
                                                :
                                                val.attachmentType === "application/vnd.ms-excel" || val.attachmentType === "text/csv" || val.attachmentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ?
                                                  <img width={150} height={100} src='/excellogo.png' />
                                                  : <img width={150} height={100} src='/msdoc.png' />
                                          }
                                        </a>
                                      </div>
                                      :
                                      <div className="chat-bubble chat-bubble--left">
                                        {val.message}
                                      </div>
                                  }
                                </div>
                              </div>
                          }
                        </>
                      })
                    }
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                Comment: {comment}
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-secondary" onClick={(e) => { props.setSupportChatId(null); props.onHide(false) }}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}


const Globalstyle = createGlobalStyle`
.container2 {
    margin: 30px auto;
    background: #fff;
    padding: 0;
    border-radius: 7px;
    height:80%;
  }
  .row.no-gutters{
    height: calc(100% - 0vh);
    }
    .chat-panel{
    overflow-y: auto;
    overflow-x: hidden;
    height: 45vh;
      }
  .addstrechg{
    display: flex;
flex-direction: column;
justify-content: stretch;
  }

  .profile-image {
    width: 50px;
    height: 50px;
    border-radius: 40px;
  }

  .settings-tray {
    background: #eee;
    padding: 10px 15px;
  }
  .settings-tray .no-gutters {
    padding: 0;
  }
  .settings-tray--right {
    float: right;
  }
  .settings-tray--right i {
    margin-top: 10px;
    font-size: 25px;
    color: grey;
    margin-left: 14px;
    transition: 0.3s;
  }
  .settings-tray--right i:hover {
    color: #74b9ff;
    cursor: pointer;
  }

  .search-box {
    background: #fafafa;
    padding: 10px 13px;
  }
  .search-box .input-wrapper {
    background: #fff;
    border-radius: 40px;
  }
  .search-box .input-wrapper i {
    color: grey;
    margin-left: 7px;
    vertical-align: middle;
  }

  input {
    border: none;
    border-radius: 30px;
    width: 80%;
  }
  input::placeholder {
    color: #e3e3e3;
    font-weight: 300;
    margin-left: 20px;
  }
  input:focus {
    outline: none;
  }

  .friend-drawer {
    padding: 10px 15px;
    display: flex;
    vertical-align: baseline;
    background: #fff;
    transition: 0.3s ease;
  }
  .friend-drawer--grey {
    background: #eee;
  }
  .friend-drawer .text {
    margin-left: 12px;
    width: 70%;
  }
  .friend-drawer .text h6 {
    margin-top: 6px;
    margin-bottom: 0;
  }
  .friend-drawer .text p {
    margin: 0;
  }
  .friend-drawer .time {
    color: grey;
  }
  .friend-drawer--onhover:hover {
    background: #74b9ff;
    cursor: pointer;
  }
  .friend-drawer--onhover:hover p,
  .friend-drawer--onhover:hover h6,
  .friend-drawer--onhover:hover .time {
    color: #fff !important;
  }

  hr {
    margin: 5px auto;
    width: 60%;
  }

  .chat-bubble {
    padding: 10px 14px;
    background: #eee;
    margin: 10px 30px;
    border-radius: 9px;
    position: relative;
    animation: fadeIn 1s ease-in;
    display:inline-block;
  }
  .chat-bubble:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-bottom: 0;
    margin-top: -10px;
  }
  .chat-bubble--left:after {
    left: 0;
    border-right-color: #eee;
    border-left: 0;
    margin-left: -20px;
  }
  .chat-bubble--right:after {
    right: 0;
    border-left-color: #74b9ff;
    border-right: 0;
    margin-right: -20px;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
 .chat-bubble.chat-bubble--right {
    background: #74b9ff;
    color: #fff;
  }

  .chat-box-tray {
    background: #eee;
    display: flex;
    align-items: baseline;
    padding: 10px 15px;
    align-items: center;
    margin-top: 19px;
    bottom: 0;
  }
  .chat-box-tray input {
    margin: 0 10px;
    padding: 6px 2px;
  }
  .chat-box-tray i {
    color: grey;
    font-size: 30px;
    vertical-align: middle;
  }
  .chat-box-tray i:last-of-type {
    margin-left: 25px;
  }
`